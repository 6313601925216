<template >
  <v-container fluid>
    <v-row>
      <v-col xs="4">
        <link-prevue :url="news[getUrl(this.$route.params.noticia)].url">
          <template slot-scope="props">
            <v-card class="mx-auto" max-width="750" >
              <v-img class="white--text align-end" height="300px" :src="props.img" :alt="props.title" >
                <v-card-title>{{props.title}}</v-card-title>
              </v-img>
              <v-card-text class="text--primary">
                <div>{{props.description}}</div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="green" text target="_blank" v-bind:href="props.url"> Ver más </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </link-prevue>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  /*import { decode_utf8 } from '@/vue-tools';*/
  import LinkPrevue from 'link-prevue';

  export default {
    computed: {
      
    },
    components: {
      LinkPrevue
    },
    data() {
      return {
        news:[
          {id:1, url:"https://www.elsoldetoluca.com.mx/local/cambiara-nombre-del-osfem-por-el-de-la-auditoria-superior-4956953.html", title:"Cambiará nombre del OSFEM por el de la Auditoría Superior", urlLocal:"11-03-2020"},
          {id:2, url:"https://www.elsoldetoluca.com.mx/local/el-dia-que-todos-salieron-a-las-calles-5457515.html", title:"El día que todos salieron a las calles", urlLocal:"06-07-2020-1"},
          {id:3, url:"https://www.elsoldetoluca.com.mx/local/disminuye-50-fallecimientos-por-covid-19-en-ecatepec-5456548.html", title:"Disminuye 50% fallecimientos por covid-19 en Ecatepec", urlLocal:"06-07-2020-2"}
        ],
        height:window.innerHeight
      }
    },
    methods: {
      getUrl(urlNoticiaLocal){
        for(var idx=0; this.news.length>idx;idx++){
          if(this.news[idx].urlLocal==urlNoticiaLocal){
            return idx;
          }
        }
      },
      /*decode(s){
        return decode_utf8(s)
      }*/
    },
  }
</script>